@import '../../../../scss/theme-bootstrap';

.header-offers-banner-item {
  color: $color-black;
  overflow: hidden;
  text-align: $ldirection;
  width: auto;
  &__offer-text {
    p {
      margin: 0;
      font-size: 17px;
      font-weight: 500;
      line-height: get-line-height(17px, 22px);
      color: $color-white;
    }
  }
  a {
    font-size: 14px;
  }
}
